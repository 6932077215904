import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor, hourRequestReasonType, HtmlTooltip } from '../../Helper/CommonThings';
import moment from 'moment';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class AdditionalHourRequest2 extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            project_id: {},
            country_code: {
                uk: 'UK',
                in: 'India',
                sl: 'Srilanka'
            },
            hours: 0,
            comment: '',
            confirm_project_id: false,
            pdmToRecv: {},
            isLoading: true,
            isLoadingTable: true,
            reason_type: 0,
        }
    }


    componentDidMount() {
        // console.log(this.props.value)
        Service.get('/bb/api/tender/project/' + this.props.value.t_tasks_data.wbs.pid_data.subproject_pid + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    project_id: res.data,
                    confirm_project_id: true,
                })

                Service.get('/bb/api/tender/pdm/?pid=' + this.props.value.t_tasks_data.wbs.pid_data.subproject_pid, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {

                        Service.get('/bb/api/tender/wbsmanager/?pid=' + this.props.value.t_tasks_data.wbs.pid_data.subproject_pid, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(reswbs => {

                                var pdmWBSData = reswbs.data

                                this.setState({
                                    // bid_data: res4.data,
                                    pdmData: res1.data,
                                    pdmWBSData: pdmWBSData
                                }, () => {
                                    this.checkPDM()
                                })

                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });

                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });


        this.fetchData();

    }

    checkPDM() {

        // console.log(this.state.pdmData)
        // console.log(this.props.value.t_tasks_data.wbs.type)
        var togo = true
        for (let i = 0; i < this.state.pdmWBSData.length; i++) {
            const element = this.state.pdmWBSData[i];
            if (element.wbsid === this.props.value.t_tasks_data.wbs.id) {
                this.setState({
                    // splPdm: false,
                    pdmToRecv: element.uid_data,
                    isLoading: false
                })
                togo = false
            }
        }
        if (togo) {
            if (this.state.pdmData.length === 0) {
                if (this.state.project_id.tenderbid_data.pdm) {
                    this.setState({
                        // splPdm: false,
                        pdmToRecv: this.state.project_id.tenderbid_data.pdm_data,
                        isLoading: false
                    })
                }
            } else {
                var gotit = false
                for (let i = 0; i < this.state.pdmData.length; i++) {
                    const element = this.state.pdmData[i];
                    if (element.pdm_type === this.props.value.t_tasks_data.wbs.type && (element.allocation_access === 1 || element.allocation_access === 2)) {
                        this.setState({
                            // splPdm: true,
                            pdmToRecv: element.uid_data,
                            isLoading: false
                        })
                        gotit = true;
                        break;
                    }
                }
                if (!gotit) {
                    if (this.state.project_id.tenderbid_data.pdm) {
                        this.setState({
                            // splPdm: false,
                            pdmToRecv: this.state.project_id.tenderbid_data.pdm_data,
                            isLoading: false
                        })
                    }
                }
            }
        }

    }

    fetchData() {
        Service.get('/bb/api/tender/hours/request/?role=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    data: res.data,
                    isLoadingTable: false
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    submitData() {
        if (this.state.hours > 0 && this.state.confirm_project_id && this.state.comment !== '') {
            this.setState({
                isLoading: true,
                isLoadingTable: true
            })
            Service.post('/bb/api/tender/hours/request/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    pid: this.props.value.t_tasks_data.wbs.pid_data.id,
                    tender_pid: this.props.value.t_tasks_data.wbs.pid_data.subproject_pid,
                    upcometender: this.state.project_id.from_tenderbid,
                    uid: user.id,
                    pdm: this.state.pdmToRecv.id,
                    hours_needed: this.state.hours,
                    comment: this.state.comment,
                    ttid: this.props.value.t_tasks_data.id,
                    twbsid: this.props.value.t_tasks_data.wbs.id,
                    trolesid: this.props.value.id,
                    reason_type: this.state.reason_type,
                    created_by: user.id
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Request sent successfully!', {
                        variant: 'success'
                    });

                    // this.handleMail(this.state.pdmToRecv.email, user.last_name + ", " + user.first_name + ' sent you additional hours request', 'Hello  ' + this.state.pdmToRecv.last_name + ', ' + this.state.pdmToRecv.first_name +
                    //     ',\n    To accept the request. Click on the below link and then click on the bell icon at the top right of the table. Below are the details for requested hours\n\n    Project: ' + this.props.projectLook[this.props.value.t_tasks_data.wbs.pid_data.subproject_pid] +
                    //     "\n    Deliverables Schedule: " + this.props.value.t_tasks_data.wbs.pid_data.name +
                    //     "\n    WBS: " + this.props.value.t_tasks_data.wbs.name +
                    //     "\n    Task: " + this.props.value.t_tasks_data.decs ? this.props.value.t_tasks_data.decs : this.props.value.t_tasks_data.name +
                    //     "\n    Role: " + this.props.value.name + "( Country: " + this.state.country_code[this.props.value.country] + " )" +
                    //     "\n    Requested Hours: " + this.state.hours +
                    //     "\n    Reason: " + this.state.comment +
                    //     "\n    Requested By: " + user.last_name + ", " + user.first_name + " ( " + user.email + ")" +
                    //     "\n\n      Click here: https://bbparam.com/home/#Available%20Workbook", 'support@bbparam.com')
                    // this.handleMail(user.email, 'Your additional request hours has been sent to the respective manager for approval', 'Hello  ' + user.last_name + ', ' + user.first_name +
                    //     ',\n    Below are the details for requested hours\n\n    Project: ' + this.props.projectLook[this.props.value.t_tasks_data.wbs.pid_data.subproject_pid] +
                    //     "\n    Deliverables Schedule: " + this.props.value.t_tasks_data.wbs.pid_data.name +
                    //     "\n    WBS: " + this.props.value.t_tasks_data.wbs.name +
                    //     "\n    Task: " + this.props.value.t_tasks_data.decs ? this.props.value.t_tasks_data.decs : this.props.value.t_tasks_data.name +
                    //     "\n    Role: " + this.props.value.name + " ( Country: " + this.state.country_code[this.props.value.country] + " )" +
                    //     "\n    Requested Hours: " + this.state.hours +
                    //     "\n    Reason: " + this.state.comment +
                    //     "\n    Sent to: " + this.state.pdmToRecv.last_name + ', ' + this.state.pdmToRecv.first_name + " ( " + this.state.pdmToRecv.email + ")", 'support@bbparam.com')
                    this.fetchData();
                    this.setState({
                        // isLoadingTable: false
                        isLoading: false,
                        hours: 0,
                        comment: ''
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar("0 hours or without Reason can't be requested!", {
                variant: 'warning'
            });
        }
    }

    handleMail(to, sub, body, reply_to) {
        // console.log(to)
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

      downloadExcel() {
     
             var sheets = ["Hours Request"]
             var sheetData = {}
             var exportData = {}
             sheetData["Hours Request"] = {
                 '!ref': "A1:O" + (this.state.data.length + 1),
                 '!cols': [
                     { wch: 35 }
                 ],
                 'A1': { t: "s", v: "Hours Requested" },
                 'B1': { t: "s", v: "Reason Type" },
                 'C1': { t: "s", v: "Reason" },
                 'D1': { t: "s", v: "Status" },
             }
             let sheet = sheetData['Hours Request']
             for (let i = 0; i < this.state.data.length; i++) {
                 let element = this.state.data[i]
                 sheet["A" + (i + 2)] = { t: "s", v: element.hours_needed }
                 sheet["B" + (i + 2)] = { t: "s", v: element.reason_type === 0 ? "NA!" : element.reason_type === 1 ? "Rework - external" : element.reason_type === 2 ? "Rework - internal" : element.reason_type === 3 ? "Additional info received" : element.reason_type === 4 ? "Change in original scope" : element.reason_type === 5 ? "Incorrect Estimate" : element.reason_type === 6 ? "Extended work scope" : "Increase within budget" }
                 sheet["C" + (i + 2)] = { t: "s", v: element.comment }
                 sheet["D" + (i + 2)] = { t: "s", v: element.status === 0 ? "Pending" : element.status === 1 ? "Accepted" : "Rejected" }
             }
             exportData = {
                 'SheetNames': sheets,
                 'Sheets': sheetData
             }
             // console.log(exportData)
             var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
             saveAs(blob, "Request History - " + moment().format("DD-MM-YYYY") + ".xlsx");
         }
     


    render() {


        const handleHourChange = (e) => {
            this.setState({ hours: e.target.value })
        };
        const handleCommentChange = (e) => {
            this.setState({ comment: e.target.value })
        };
        const handleReasonTypeChange = (event) => {
            this.setState({
                reason_type: event.target.value
            })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <Box fontWeight="fontWeightMedium">EM/DDM :</Box>{" " + this.state.pdmToRecv.last_name + ", " + this.state.pdmToRecv.first_name + " (" + this.state.pdmToRecv.email + ")"}
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <FormControl variant="outlined" style={{ width: '500px' }} >
                                <InputLabel id="demo-simple-select-outlined-label">Reason Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.reason_type}
                                    onChange={handleReasonTypeChange}
                                    label="Reason Type"
                                >
                                    <MenuItem value={0}></MenuItem>
                                    {[1, 2, 3, 4, 5, 6, 7].map((item) =>
                                        <MenuItem value={item}>
                                            <HtmlTooltip
                                                style={{ width: '100%' }}
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">{hourRequestReasonType(item).menu}</Typography>
                                                        {hourRequestReasonType(item).note}
                                                    </React.Fragment>
                                                }
                                            >
                                                <Box display="flex">{hourRequestReasonType(item).menu}</Box>
                                            </HtmlTooltip>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField id="outlined-basic3" style={{ width: '220px', marginLeft: '10px' }} value={this.state.hours} onChange={handleHourChange} label="Hours Needed" type="number" variant="outlined" />
                            <TextField id="outlined-basic4" style={{ marginLeft: '10px' }} fullWidth value={this.state.comment} onChange={handleCommentChange} label="Reason" variant="outlined" />
                            <Box alignItems="center" m={1}>
                                <Button variant="contained" color="primary" onClick={() => this.submitData()}
                                    disabled={!(this.state.hours && this.state.comment && this.state.reason_type)}
                                >Submit</Button>
                            </Box>
                        </Box>

                        <MaterialTable
                            title='Additional Hours Requested'
                            columns={[
                                // {
                                //     title: 'Project', field: 'role_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid',
                                //     lookup: this.props.projectLook
                                // },
                                // {
                                //     title: 'Deliverables Schedule', field: 'role_data.t_tasks_data.t_wbs_data.pid_data.name',

                                // },
                                // {
                                //     title: 'WBS', field: 'role_data.t_tasks_data.t_wbs_data.name',

                                // },
                                // {
                                //     title: 'Tasks', field: 'role_data.t_tasks_data.name',
                                //     render: rowData => rowData.role_data.t_tasks_data.decs ? rowData.role_data.t_tasks_data.decs : rowData.role_data.t_tasks_data.name,

                                // },
                                // {
                                //     title: 'Role', field: 'role_data.name',

                                // },
                                {
                                    title: 'Hours Requested', field: 'hours_needed',

                                },
                                {
                                    title: 'Reason Type', field: 'reason_type', editable: 'never',
                                    lookup: { 0: 'NA!', 1: 'Rework - external', 2: 'Rework - internal', 3: 'Additional info received', 4: 'Change in original scope', 5: 'Incorrect Estimate', 6: 'Extended work scope', 7: 'Increase within budget', },
                                },
                                {
                                    title: 'Reason', field: 'comment',

                                },
                                {
                                    title: 'Status', field: 'status',
                                    lookup: { 0: 'Pending', 1: 'Accepted', 2: 'Rejected', }

                                },
                                // {
                                //     title: 'User', field: 'name', editable: 'never',
                                //     render: rowData => <Typography>{rowData.user_data.first_name + " " + rowData.user_data.last_name + " (" + rowData.user_data.email + ")"}</Typography>
                                // },
                                // {
                                //     title: 'Roles', field: 'user_data.role_id',
                                //     lookup: this.props.rolesLookUp
                                // },
                                // {
                                //     title: 'Type', field: 'assign_type',
                                //     lookup: { 0: 'Assigned', 1: 'Reviewer', 100: 'Not Assigned', }
                                // },
                                // {
                                //     title: 'Work Estimated Start', field: 'start', type: 'date', default: this.props.task.from_date,
                                // },
                                // {
                                //     title: ' Work Estimated End', field: 'end', type: 'date', default: this.props.task.to_date,
                                // },
                                // // {
                                // //     title: 'Actual Hours', field: 'ah', editable: 'never',
                                // //     render: rowData => <Typography>20</Typography>
                                // // },
                                // {
                                //     title: 'Allocated/Planned Hours', field: 'planed_hours', type: 'numeric',
                                // },
                            ]}
                            isLoading={this.state.isLoadingTable}
                            data={this.state.data}
                            options={{
                                padding: "dense",
                            }}
                            actions={[
                                {
                                    icon: 'download',
                                    tooltip: 'Excel Download',
                                    isFreeAction: true,
                                    disabled: this.state.data.length === 0,
                                    onClick: (event, rowData) => {
                                        this.downloadExcel()
                                    }
                                }
                            ]}
                        />

                    </div>
                )}
            </div>


        )
    }
}

export default withSnackbar(AdditionalHourRequest2)