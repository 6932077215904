import React, { Component } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import MaterialTable from 'material-table';
import moment from "moment";
import { Box, Button, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { CsvBuilder } from 'filefy';
import { errorMessage } from '../../Helper/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getTimesheetReportColumns } from '../../Helper/TimesheetReportColumns';
// import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { stringToDate } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

var index = null

class TeamTimesheetReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            startDate: new Date(),
            endDate: new Date(),
            weeksData: [],
            selectedWeeks: null,
            weeksTitle: false,
            datesTitle: false,
            company: props.company ? props.company : 0
        }
    }

    componentDidMount() {
        this.fetchOps()
        this.weekList()
    }

    fetchOps() {
        if (this.props.dateValue && this.props.dateValue.length !== 0) {
            var date1 = this.props.dateValue[0]
            var date2 = this.props.dateValue[1]
            this.fetchOps2(this.props.dateValue[0], this.props.dateValue[1])
            this.setState({ startDate: stringToDate(date1, "dd-mm-yyyy", "-"), endDate: stringToDate(date2, "dd-mm-yyyy", "-") })
        }
    }

    fetchOps1() {
        this.setState({
            isLoading: true,
            datesTitle: false,
            weeksTitle: false,
            selectedWeeks: null,
        })
        var date1 = moment(this.state.startDate).format('DD-MM-YYYY')
        var date2 = moment(this.state.endDate).format('DD-MM-YYYY')
        var url = "/bb/api/report/view/get_timesheet_report/?start=" + date1 + "&end=" + date2
        if (this.props.gid) {
            url = "/bb/api/report/view/get_timesheet_report/?start=" + date1 + "&end=" + date2 + "&gid=" + this.props.gid
        }

        if (this.state.company && this.state.company !== 0) {
            url += "&company=" + this.state.company
        }

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false, datesTitle: true })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps2(date1_str, date2_str) {
        this.setState({
            isLoading: true,
            datesTitle: false,
            weeksTitle: false,
        })
        var date1 = date1_str
        var date2 = date2_str
        var url = "/bb/api/report/view/get_timesheet_report/?start=" + date1 + "&end=" + date2
        if (this.props.gid) {
            url = "/bb/api/report/view/get_timesheet_report/?start=" + date1 + "&end=" + date2 + "&gid=" + this.props.gid
        }

        if (this.state.company && this.state.company !== 0) {
            url += "&company=" + this.state.company
        }

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false, weeksTitle: true })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps3() {
        if (!this.state.selectedWeeks) {
            this.props.enqueueSnackbar("Please select a week!", {
                variant: 'warning'
            });
        } else {
            this.fetchOps2(this.state.selectedWeeks.start, this.state.selectedWeeks.end)
        }
    }

    weekList() {
        var weeks_list = []
        var year_start = moment().subtract(1, 'years')
        var start_date = year_start

        while (start_date <= moment().add(1, 'week')) {
            weeks_list.push({
                name: moment(start_date).format('YYYY') + " Week " + (start_date.endOf('week').isoWeek()) + " (" + (start_date.startOf('week').format('DD-MM-YYYY') + " - "
                    + start_date.endOf('week').format('DD-MM-YYYY')) + ")",
                start: start_date.startOf('week').format('DD-MM-YYYY'),
                end: start_date.endOf('week').format('DD-MM-YYYY'),
            })
            start_date = start_date.endOf('week').add(1, 'day')
        }
        this.setState({
            weeksData: weeks_list.reverse()
        }, () => {
            if (this.props.dateValue && this.props.dateValue.length !== 0) {
                for (let i = 0; i < this.state.weeksData.length; i++) {
                    const element = this.state.weeksData[i];
                    if (element.start === this.props.dateValue[0] && element.end === this.props.dateValue[1]) {
                        this.setState({
                            selectedWeeks: element
                        })
                        index = i
                        break
                    }
                }
            }
        })
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleDateChange2 = (date) => {
        this.setState({
            endDate: date
        })
    }

    onSelectedWeeks = (event, values) => {
        if (values) {
            index = this.state.weeksData.findIndex((element) => element.name === values.name)
            this.setState({
                selectedWeeks: values,
                weeksTitle: false,
                startDate: stringToDate(values.start, "dd-mm-yyyy", "-"),
                endDate: stringToDate(values.end, "dd-mm-yyyy", "-")
            })
        } else {
            this.setState({
                selectedWeeks: null,
                weeksTitle: false,
                startDate: new Date(),
                endDate: new Date()
            });
            index = null
        }
    }

    handleFront = () => {
        index = index + 1
        this.setState({
            selectedWeeks: this.state.weeksData[index],
        }, () => {
            this.setState({
                startDate: stringToDate(this.state.selectedWeeks.start, "dd-mm-yyyy", "-"),
                endDate: stringToDate(this.state.selectedWeeks.end, "dd-mm-yyyy", "-")
            })
            this.fetchOps2(this.state.selectedWeeks.start, this.state.selectedWeeks.end)
        });
    }

    handleBack = () => {
        index = index - 1
        this.setState({
            selectedWeeks: this.state.weeksData[index],
        }, () => {
            this.setState({
                startDate: stringToDate(this.state.selectedWeeks.start, "dd-mm-yyyy", "-"),
                endDate: stringToDate(this.state.selectedWeeks.end, "dd-mm-yyyy", "-")
            })
            this.fetchOps2(this.state.selectedWeeks.start, this.state.selectedWeeks.end)
        });
    }

    downloadtimesheetReport(rowData) {
        // console.log(rowData)
        var url = `/bb/api/task/approve/timesheet_report/?start=${rowData.start}&end=${rowData.end}&uid=${rowData.id}&overheads=1&rate_and_cost=1`

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.status === 204) {
                    alert("There is no record for the selected date range.")
                    this.setState({
                        isLoading: false
                    })
                } else {
                    var data = getTimesheetReportColumns()

                    data.splice(22, 0, {
                        title: 'Rate',
                        field: 'ts_rate',
                        render: rowData => rowData.ts_rate ? rowData.ts_rate : "NA"
                    },
                        {
                            title: 'Amount',
                            field: 'ts_cost',
                            render: rowData => rowData.ts_cost ? rowData.ts_cost : "NA"
                        },)

                    this.exportCsv(data, res.data, rowData.last_name + ", " + rowData.first_name + " Timesheet Report for " + rowData.start + " - " + rowData.end + '.csv')
                }

            })
            .catch(error => {
                console.log(error)
                if (error.status === 406) {
                    alert("You are not authorized for the report.")
                } else {
                    // this.setState({
                    //     isLoading: false
                    // })
                    errorMessage(this.props, error)
                }
            });
    }

    exportCsv = (columnList, initialData, name) => {
        const columns = columnList.filter(columnDef => {
            return !columnDef.hidden && columnDef.field && columnDef.export !== false;
        });

        const data = initialData.map(rowData =>
            columns.map(columnDef => {
                return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
            })
        );

        const builder = new CsvBuilder(name);
        builder
            .setDelimeter(',')
            .setColumns(columns.map(columnDef => columnDef.title))
            .addRows(data)
            .exportFile();
    };


    filterCompany(company) {
        let startDate = moment(new Date()).format('DD-MM-YYYY')
        let endDate = moment(new Date()).format('DD-MM-YYYY')
        let startDate1 = moment(this.state.startDate).format('DD-MM-YYYY')
        let endDate1 = moment(this.state.endDate).format('DD-MM-YYYY')
        if (startDate === startDate1 && endDate === endDate1 && !this.state.selectedWeeks) {
            this.setState({ company: company }, () => {
                this.fetchOps()
            })
        } else if (!this.state.selectedWeeks) {
            this.setState({ company: company }, () => {
                this.fetchOps1()
            })
        } else {
            this.setState({ company: company }, () => {
                this.fetchOps3()
            })
        }
    }

    render() {
        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                        display="flex"
                        justifyContent={'center'}
                        alignItems="center"
                        p={1}
                        m={1}
                    >

                        <Box p={1}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Start Date"
                                format="dd/MM/yyyy"
                                value={this.state.startDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>

                        <Box p={1}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog1"
                                label="End Date"
                                format="dd/MM/yyyy"
                                value={this.state.endDate}
                                onChange={this.handleDateChange2}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>

                        <Box p={1}>
                            <Button variant="contained" color="primary" onClick={() => this.fetchOps1()}>Fetch Data</Button>
                        </Box>

                        {/* <Box
                                display="flex"
                                alignItems="center"
                                m={1}
                            > */}

                        <Autocomplete
                            // multiple
                            id="checkboxes-tags-demo"
                            options={this.state.weeksData}
                            value={this.state.selectedWeeks}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            onChange={this.onSelectedWeeks}
                            // fullWidth
                            style={{ width: 400, marginLeft: '10px' }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Weeks" placeholder="Favorites" />
                            )}
                        />
                        <Button style={{ marginLeft: '10px' }} variant="contained" color="primary" onClick={() => this.fetchOps3()}>Fetch Data</Button>
                        {/* </Box> */}
                    </Box>


                </MuiPickersUtilsProvider>

                <MaterialTable
                    title={(this.state.weeksTitle && this.state.selectedWeeks) ?
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Box>
                                <b>{this.state.selectedWeeks.name}</b>
                            </Box>
                            <Box marginLeft={'10px'}>
                                <Button
                                    size='small'
                                    variant='contained'
                                    disabled={!index ? true : false}
                                    color='primary'
                                    onClick={() => this.handleFront()}
                                    startIcon={<ArrowBackIosIcon fontSize='small' />}>
                                    Prev week
                                </Button>
                            </Box>
                            <Box marginLeft={'10px'}>
                                <Button
                                    size='small'
                                    variant='contained'
                                    disabled={this.state.weeksData.length === (index + 1)}
                                    color='primary'
                                    onClick={() => this.handleBack()}
                                    endIcon={<ArrowForwardIosIcon fontSize='small' />}>
                                    next week
                                </Button>
                            </Box>
                        </Box> : (this.state.datesTitle && this.state.startDate && this.state.endDate) ? (moment(this.state.startDate).format('DD-MM-YYYY') + " - " + moment(this.state.endDate).format('DD-MM-YYYY')) : null}
                    columns={[
                        {
                            title: 'Name', field: 'user_name',
                        },
                        {
                            title: 'Project', field: 'project_name',
                        },
                        {
                            title: 'DS', field: 'ds_name',
                        },
                        {
                            title: 'WBS', field: 'wbs_name',
                        },
                        {
                            title: 'Task', field: 'task_name',
                        },
                        {
                            title: 'Date', field: 'date',
                        },
                        {
                            title: 'Hours', field: 'actual_hours',
                        },
                        {
                            title: 'Task Status', field: 'task_percentage',
                            render: rowData => (rowData.task_percentage !== null) ? rowData.task_percentage + "%" : "NA"
                        }
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        search: true,
                        filtering: true,
                        // actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50],
                        paging: false,
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            this.exportCsv(columns, data, "Timesheet Report " + moment().format('MM/DD/YYYY hh:mm') + '.csv')
                        },
                    }}
                // actions={[
                //     {
                //         icon: () => <SaveAltIcon />,
                //         tooltip: 'Download User Timesheet Report',
                //         onClick: (event, rowData) => this.downloadtimesheetReport(rowData)
                //     }
                // ]}
                />

            </div>
        )
    }
}

export default withSnackbar(TeamTimesheetReport)