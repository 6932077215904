import MaterialTable from 'material-table'
import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCookie } from '../../Helper/CookieHelper';
import Moment from 'react-moment';
import Service, { getBlobSASURL } from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DescriptionIcon from '@material-ui/icons/Description';
import { BootstrapDialogTitleV4 } from '../../Helper/DialogTitleM5';
import { Avatar, DialogContent, ListItem, ListItemText } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import ProjectRequestForm from '../../RequestForms/ProjectRequestForm';

const token = getCookie("bb_ts_token")

class ProjectRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleInput: null,
            approveDialog: false,
            rejectDialog: false,
            name: '',
            rowData: {},
            isLoading: true,
            approveDisable: false,
            rejectDisable: false,
            files: [],
            openFileDialog: false,
            file: null,
            openViewDialog: false,
            loaded: true,
            editData: null,
            editDialog: false
        }
        this.reload = this.reload.bind(this)
    }

    getData() {
        this.setState({ isLoading: true })
        Service.get(`/bb/api/new/project/request`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data
                }, () => {
                    this.setState({
                        isLoading: false
                    })
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    approveFunc(rowData) {
        this.setState({
            approveDialog: true,
            name: rowData.first_name,
            rowData: rowData
        })
    }

    rejectFunc(rowData) {
        this.setState({
            rejectDialog: true,
            rowData: rowData
        })
    }

    handleApprovalClose() {
        this.setState({
            approveDialog: false,
            roleInput: null
        })
    }

    handleRejectClose() {
        this.setState({
            rejectDialog: false
        })
    }

    approval() {
        this.setState({ approveDisable: true })
        let data = {
            "id": this.state.rowData.id,
            "status": 1,
        }
        Service.post('/bb/api/new/project/request/new_project_approve/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.setState({
                    approveDialog: false,
                    isLoading: true
                }, () => {
                    this.getData()
                    this.setState({ approveDisable: false })
                })
                this.props.enqueueSnackbar('User Approved Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                if (error.status === 406) {
                    this.props.enqueueSnackbar('The project with same name already exists!', {
                        variant: 'warning'
                    })
                    this.setState({ approveDisable: false })
                } else {
                    console.log(error)
                    errorMessage(this.props, error)
                }
            });
    }

    reject() {
        this.setState({ rejectDisable: true })
        let data = {
            "id": this.state.rowData.id,
            "status": 2,
        }
        Service.post('/bb/api/new/project/request/new_project_reject/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.setState({
                    rejectDialog: false,
                    isLoading: true
                }, () => {
                    this.getData()
                    this.setState({ rejectDisable: false })
                })
                this.props.enqueueSnackbar('User Rejected Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    componentDidMount() {
        this.getData()
    }

    openFiles(data) {
        this.setState({ files: data.file_location, openFileDialog: true })
    }

    viewFiles(item) {
        let blob_obj = getBlobSASURL()

        if (blob_obj) {
            const blobSasUrl = blob_obj.cdn_domain + "/" + item.file_path + blob_obj.connection_string
            window.open(blobSasUrl)
        } else {
            this.props.enqueueSnackbar('Blob not available!', {
                variant: 'warning'
            });
        }
    }

    reload() {
        this.getData()
        this.setState({ editDialog: false, editData: null })
    }

    render() {

        const handleFileDialogClose = (e) => {
            this.setState({
                openFileDialog: false,
                files: []
            })
        };

        return (
            <div>
                <MaterialTable
                    title={'Project Request'}
                    isLoading={this.state.isLoading}
                    columns={[
                        {
                            title: 'Project Name', field: 'project_name'
                        },
                        {
                            title: 'Lead Discipline', field: 'lead_discipline', lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                        },
                        {
                            title: 'Region', field: 'region_name'
                        },
                        {
                            title: 'Client', field: 'client_name'
                        },
                        {
                            title: 'Contract Value', field: 'contract_value'
                        },
                        {
                            title: 'Tender Submission', field: 'tender_submission', type: 'date'
                        },
                        {
                            title: 'Contract Award', field: 'contract_award', type: 'date'
                        },
                        {
                            title: 'Contract Completion', field: 'contract_completion', type: 'date'
                        },
                        {
                            title: 'Requested By', render: rowData => rowData.requested_by_last_name + ", " + rowData.requested_by_first_name
                        },
                        {
                            title: 'Status', field: 'status', lookup: { 0: 'Pending', 1: 'Approved', 2: "Rejected" }
                        },
                        {
                            title: 'Actioned By', field: 'approved_by_name', render: rowData => rowData.status > 0 ? rowData.approved_by_last_name + ", " + rowData.approved_by_first_name : null
                        },
                        {
                            title: 'Date Created', field: 'date_created', editable: 'never', defaultSort: 'desc',
                            
                            render: rowData => <Moment local format="DD/MM/YYYY hh:mm A" >{rowData.date_created}</Moment>
                        },
                        {
                            title: 'Attachments', field: 'file_location',editable:'never',
                            render: rowData => (!rowData.file_location) ? null : <Button size="small" color="primary" onClick={() => { this.openFiles(rowData) }} >View</Button>
                        },
                    ]}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1
                    }}
                    data={this.state.data}
                    actions={[
                        rowData => ({
                            disabled: rowData.status !== 0,
                            icon: () => <EditIcon />,
                            tooltip: 'edit',
                            onClick: (event, rowData) => { this.setState({ editData: rowData }, () => { this.setState({ editDialog: true }) }) },
                        }),
                        rowData => ({
                            disabled: rowData.status !== 0,
                            icon: () => <DoneIcon />,
                            tooltip: 'Approve',
                            onClick: (event, rowData) => { this.approveFunc(rowData) },
                        }),
                        rowData => ({
                            disabled: rowData.status !== 0,
                            icon: () => <CloseIcon />,
                            tooltip: 'Reject',
                            onClick: (event, rowData) => { this.rejectFunc(rowData) },
                        }),
                    ]}
                />
                <Dialog open={this.state.approveDialog}>
                    {/* <DialogTitle>Please Select {this.state.name}'s Role</DialogTitle> */}
                    <DialogTitle>
                        <Box margin={2}>
                            Are you sure, do you want to approve this project?
                        </Box>
                    </DialogTitle>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.handleApprovalClose()}>Close</Button>
                        <Button color='primary' disabled={this.state.approveDisable} onClick={() => this.approval()}>Approve</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.rejectDialog}>
                    {/* <DialogTitle>Please Select {this.state.name}'s Role</DialogTitle> */}
                    <DialogTitle>
                        <Box margin={2}>
                            Are you sure, do you want to reject this project?
                        </Box>
                    </DialogTitle>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.handleRejectClose()}>Close</Button>
                        <Button color='primary' disabled={this.state.rejectDisable} onClick={() => this.reject()}>Reject</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openFileDialog} fullWidth onClose={handleFileDialogClose} aria-labelledby="form-dialog-title">
                    <BootstrapDialogTitleV4 onClose={handleFileDialogClose} id="form-dialog-title">Uploaded Files</BootstrapDialogTitleV4>
                    <DialogContent>
                        {this.state.files.map((text, index) => (
                            <ListItem button key={index} onClick={() => this.viewFiles(text)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={text.file_name} />
                            </ListItem>
                        ))}
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.editDialog}>
                    <BootstrapDialogTitleV4 onClose={() => this.setState({ editDialog: false, editData: null })}>
                        Edit Request
                    </BootstrapDialogTitleV4>
                    <DialogContent>
                        <ProjectRequestForm editData={this.state.editData} reload={this.reload} reloadProp={true} />
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}

export default withSnackbar(ProjectRequest)
