import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service, { getBlobSASURL } from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import Moment from 'react-moment';
import { getCookie } from '../Helper/CookieHelper';
import { getPrimaryColor, sortByProperty } from '../Helper/CommonThings';
import { errorMessage } from '../Helper/ErrorMessage';
import { Button } from '@mui/material';
import { Avatar, DialogContent, ListItem, ListItemText } from '@mui/material';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DescriptionIcon from '@material-ui/icons/Description';
import { BootstrapDialogTitleV4 } from '../Helper/DialogTitleM5';
import Dialog from '@material-ui/core/Dialog';

const token = getCookie("bb_ts_token");

class SupportManager extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            issueName: '',
            issueComment: '',
            isLoading: true,
            usersLookup: {},
            usersData: [],
            usersEmail: {},
            files: [],
            openFileDialog: false,
            file: null,
            openViewDialog: false,
            loaded: true
        }
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        var url = "/bb/api/helps/";
        // if (user.email === 'yaageshprasad.prakasam@balfourbeatty.com') {
        //     url = "/bb/api/helps/";
        // } else {
        //     url = "/bb/api/helps/?uid="+user.id;
        // }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.uid_mod = "id" + element.uid
                }
                // console.log(res.data)
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {

                var responceData = res1.data.sort(sortByProperty('last_name'))

                // console.log(responceData)

                var userLookup = {}, usersEmail = {}
                for (let i = 0; i < responceData.length; i++) {
                    const element = responceData[i];
                    userLookup["id" + element.id] = element.last_name + ", " + element.first_name + " (" + element.email + ")";
                    usersEmail[element.id] = element.email
                }

                this.setState({ usersData: res1.data, usersLookup: userLookup, usersEmail: usersEmail })

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(putData, oldData) {
        Service.put('/bb/api/helps/helps_update/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData
        })
            .then(res => {

                if (oldData.status && putData.status === 2) {
                    this.handleMail(this.state.usersEmail[putData.uid], 'Your complaint with reference number ' + res.data.id + ' is resolved', 'Dear User,\n\n    Thank you for writing to PaRaM.\n\n    Issue: ' + putData.name + "\n    Comment: " + putData.comment, 'support@bbparam.com')
                }

                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
                errorMessage(this.props, error)
            });
    }

    handleMail(to, sub, body, reply_to) {
        // console.log(to)
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

    // status1(rowData) {
    //     if (!rowData.is_active) {
    //         return 'check_circle';
    //     } else {
    //         return 'hourglass_empty';
    //     }
    // }

    // status2(rowData) {
    //     if (!rowData.is_active) {
    //         return 'Resolved';
    //     } else {
    //         return 'Working On It';
    //     }
    // }

    openFiles(data) {
        this.setState({ files: data.file_location, openFileDialog: true })
    }

    viewFiles(item) {
        let blob_obj = getBlobSASURL()

        if (blob_obj) {
            const blobSasUrl = blob_obj.cdn_domain + "/" + item.file_path + blob_obj.connection_string
            window.open(blobSasUrl)
        } else {
            this.props.enqueueSnackbar('Blob not available!', {
                variant: 'warning'
            });
        }
    }

    render() {

        const handleFileDialogClose = (e) => {
            this.setState({
                openFileDialog: false,
                files: []
            })
        };

        // const handleViewDialogClose = (e) => {
        //     this.setState({
        //         openViewDialog: false,
        //         file: null
        //     })
        // };

        // const handleClose = (e) => {
        //     this.setState({ openAdd: false })
        // };

        // const handleName = (e) => {
        //     this.setState({ issueName: e.target.value })
        // };

        // const handleComm = (e) => {
        //     this.setState({ issueComment: e.target.value })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {/* <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ marginBottom: '10px' }}>Add Issue</Button>
                            </Box> */}
                        <MaterialTable
                            title="Support Team use only"
                            columns={[
                                { title: 'ID', field: 'id', editable: 'never' },
                                {
                                    title: 'Member', field: 'uid_mod', editable: 'never',
                                    lookup: this.state.usersLookup
                                },
                                { title: 'Category', field: 'category', lookup: { 'defects': 'Defects', 'requirements': 'Requirements' } },
                                { title: 'Issue', field: 'name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, editable: 'never' },
                                { title: 'Comment', field: 'comment', headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }, editable: 'never' },
                                {
                                    title: 'Status', field: 'status',
                                    lookup: { 0: 'Pending', 2: 'Resolved', 1: 'In Progress', 3: 'Feedback Taken' }
                                },
                                {
                                    title: 'Date of Occurance', field: 'date_of_occurrence', type: 'date', editable: 'never',
                                    render: rowData => rowData.category === "defects" && rowData.date_of_occurrence ? <Moment local format="DD/MM/YYYY">{rowData.date_of_occurrence}</Moment> : "NA",

                                },
                                {
                                    title: 'Date Created', field: 'date_created', editable: 'never', defaultSort: 'desc',
                                    render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>
                                },
                                {
                                    title: 'Attachments', field: 'file_location', editable: 'never',
                                    render: rowData => (!rowData.file_location) ? null : <Button size="small" color="primary" onClick={() => { this.openFiles(rowData) }} >View</Button>
                                },
                            ]}
                            data={this.state.data}
                            // actions={[
                            //     rowData => ({
                            //         icon: this.status1(rowData),
                            //         tooltip: this.status2(rowData),
                            //     }),
                            // ]}
                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                pageSizeOptions: [5, 10, 20],
                                paging: false
                            }}
                            editable={{
                                // onRowAdd: newData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             if (!newData.uid_mod || newData.uid_mod === '' || newData.uid_mod === undefined || !newData.name || newData.name === '' || newData.name === undefined || !newData.comment || newData.comment === '' || newData.comment === undefined) {
                                //                 this.props.enqueueSnackbar('Fill Member, Issue, Comment!', {
                                //                     variant: 'warning'
                                //                 });
                                //             } else {
                                //                 this.setState({ save: false });
                                //                 this.setState(prevState => {
                                //                     const data = [...prevState.data];
                                //                     newData.roles = [];
                                //                     data.push(newData);
                                //                     this.postData(newData);
                                //                     return { ...prevState, data };
                                //                 });
                                //             }

                                //         }, 60);
                                //     }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData && oldData.uid_mod === newData.uid_mod) {
                                                this.setState({ save: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = newData;
                                                    this.putData(newData, oldData);
                                                    return { ...prevState, data };
                                                });
                                            } else {
                                                this.props.enqueueSnackbar('Member should not be edited!', {
                                                    variant: 'warning'
                                                });
                                            }
                                        }, 60);

                                    }),
                                // onRowDelete: oldData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState({ save: false });
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 data.splice(data.indexOf(oldData), 1);
                                //                 this.deleteData(oldData)
                                //                 return { ...prevState, data };
                                //             });
                                //         }, 60);
                                //     }),
                            }}
                        />
                        {/* <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Add Issue</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Warning : This can't be changed later. Please double check before submitting.
                                    </DialogContentText>
                                    <TextField id="outlined-basic" label="Issue" variant="outlined"
                                        value={this.state.issueName}
                                        onChange={handleName}
                                        fullWidth
                                        style={{ margin: '5px' }}
                                    />
                                    <TextField id="outlined-basic" label="Comment" variant="outlined"
                                        value={this.state.issueComment}
                                        onChange={handleComm}
                                        fullWidth
                                        style={{ margin: '5px' }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={e => this.postData()} color="primary">
                                        Add Issue
                                    </Button>
                                </DialogActions>
                            </Dialog> */}

                        <Dialog open={this.state.openFileDialog} fullWidth onClose={handleFileDialogClose} aria-labelledby="form-dialog-title">
                            <BootstrapDialogTitleV4 onClose={handleFileDialogClose} id="form-dialog-title">Uploaded Files</BootstrapDialogTitleV4>
                            <DialogContent>
                                {this.state.files.map((text, index) => (
                                    <ListItem button key={index} onClick={() => this.viewFiles(text)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <DescriptionIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={text.file_name} />
                                    </ListItem>
                                ))}
                            </DialogContent>
                        </Dialog>

                        {/* <Dialog open={this.state.openViewDialog} fullWidth onClose={handleViewDialogClose} aria-labelledby="form-dialog-title">
                            <BootstrapDialogTitleV4 onClose={handleViewDialogClose} id="form-dialog-title">Uploaded Files</BootstrapDialogTitleV4>
                            <DialogContent>
                                {this.state.loaded ?
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                        <CircularProgress color='primary' />
                                    </Box> :
                                    <iframe src={this.state.file} title={this.state.file} />
                                }
                            </DialogContent>
                        </Dialog> */}
                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(SupportManager);