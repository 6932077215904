import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service, { getBlobSASURL } from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LoopCircleLoading } from 'react-loadingg';
import Moment from 'react-moment';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import DefectsAndReqForm from './DefectsAndReqForm';
import { Avatar, DialogContent, ListItem, ListItemText, Link, Typography } from '@mui/material';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DescriptionIcon from '@material-ui/icons/Description';
import { BootstrapDialogTitleV4 } from '../Helper/DialogTitleM5';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import { Box } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
// import moment from 'moment';
// 


const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));



class Helps extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            issueName: null,
            issueComment: null,
            isLoading: true,
            issue: null,
            files: [],
            openFileDialog: false,
            file: null,
            openViewDialog: false,
            loaded: true
        }
        this.handleBackClose = this.handleBackClose.bind(this)
        this.handleGetData = this.handleGetData.bind(this)
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        var url = "/bb/api/helps/?uid=" + user.id;
        // if (user.email === 'yaageshprasad.prakasam@balfourbeatty.com') {
        //     url = "/bb/api/helps/";
        // } else {
        //     url = "/bb/api/helps/?uid=" + user.id;
        // }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }



    handleMail(to, sub, body, reply_to) {
        // console.log(to)
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

    status1(rowData) {
        if (!rowData.is_active) {
            return 'check_circle';
        } else {
            return 'hourglass_empty';
        }
    }

    status2(rowData) {
        if (!rowData.is_active) {
            return 'Resolved';
        } else {
            return 'Working On It';
        }
    }

    handleBackClose() {
        this.setState({
            openAdd: false
        }, () => {
            this.setState({ issue: null, issueComment: null })
        })
    }

    handleGetData() {
        this.fetchOps()
    }

    openFiles(data) {
        this.setState({ files: data.file_location, openFileDialog: true })
    }

    viewFiles(item) {
        let blob_obj = getBlobSASURL()

        if (blob_obj) {
            const blobSasUrl = blob_obj.cdn_domain + "/" + item.file_path + blob_obj.connection_string
            window.open(blobSasUrl)
        } else {
            this.props.enqueueSnackbar('Blob not available!', {
                variant: 'warning'
            });
        }
    }


    render() {

        const handleClose = (e) => {
            this.setState({
                openAdd: false
            }, () => {
                this.setState({ issue: null, issueComment: null })
            })
        };

        const handleFileDialogClose = (e) => {
            this.setState({
                openFileDialog: false,
                files: []
            })
        };

        // const handleViewDialogClose = (e) => {
        //     this.setState({
        //         openViewDialog: false,
        //         file: null
        //     })
        // };
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {/* <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                            <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ marginBottom: '10px' }}>Raise a Request</Button>
                        </Box> */}

                        <MaterialTable
                            title={
                                <div>
                                    <p style={{ color: getDarkMode() === 'dark' ? '#aeb94d' : "#000080" }}>Please cast the issue on (For more help share your Issue ID with support@bbparam.com).</p>
                                    <p style={{ color: getDarkMode() === 'dark' ? '#b96c3f' : "#800040" }}>To drop your feedback please tap on the feedback icon over the top right corner.</p>
                                </div>
                            }
                            columns={[
                                { title: 'ID', field: 'id' },
                                { title: 'Category', field: 'category', lookup: { 'defects': 'Defects/Error/Other Issues', 'requirements': 'Requirements, Developments & Improvements' } },
                                { title: 'Issue', field: 'name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } },
                                { title: 'Comment', field: 'comment', headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 } },
                                {
                                    title: 'Status', field: 'status',
                                    lookup: { 0: 'Pending', 2: 'Resolved', 1: 'In Progress', 3: 'Feedback Taken' }
                                },
                                {
                                    title: 'Date of Occurance', field: 'date_of_occurrence',
                                    render: rowData => rowData.category === "defects" && rowData.date_of_occurrence ? <Moment local format="DD/MM/YYYY">{rowData.date_of_occurrence}</Moment> : "NA",

                                },
                                {
                                    title: 'Date Created', field: 'date_created', defaultSort: 'desc',
                                    render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>,

                                },
                                {
                                    title: 'Attachments', field: 'file_location',editable:'never',
                                    render: rowData => (!rowData.file_location) ? null : <Button size="small" color="primary" onClick={() => { this.openFiles(rowData) }} >View</Button>
                                },

                            ]}
                            data={this.state.data}
                            actions={[
                                {
                                    icon: () => <Button variant="contained" color="primary" startIcon={<HelpIcon />} onClick={e => { this.setState({ openAdd: true }) }} style={{ marginBottom: '10px' }}>Raise a Request</Button>,
                                    isFreeAction: true,
                                }
                            ]}
                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30],
                                sorting: true
                            }}
                        />
                        <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">  <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box flexGrow={1}>
                                    <Typography variant='h5'>Raise a Request</Typography>
                                </Box>
                                <Box>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                        }}
                                        color={getMode() ? 'secondary' : 'primary'}
                                    >
                                        FAQ's
                                    </Link>
                                </Box>
                            </Box></DialogTitle>
                            <DefectsAndReqForm close={this.handleBackClose} getData={this.handleGetData} />
                        </Dialog>

                        <Dialog open={this.state.openFileDialog} fullWidth onClose={handleFileDialogClose} aria-labelledby="form-dialog-title">
                            <BootstrapDialogTitleV4 onClose={handleFileDialogClose} id="form-dialog-title">Uploaded Files</BootstrapDialogTitleV4>
                            <DialogContent>
                                {this.state.files.map((text, index) => (
                                    <ListItem button key={index} onClick={() => this.viewFiles(text)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <DescriptionIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={text.file_name} />
                                    </ListItem>
                                ))}
                            </DialogContent>
                        </Dialog>

                        {/* <Dialog open={this.state.openViewDialog} fullWidth onClose={handleViewDialogClose} aria-labelledby="form-dialog-title">
                            <BootstrapDialogTitleV4 onClose={handleViewDialogClose} id="form-dialog-title">Uploaded Files</BootstrapDialogTitleV4>
                            <DialogContent>
                                {this.state.loaded ?
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                        <CircularProgress color='primary' />
                                    </Box> :
                                    <iframe src={this.state.file} title={this.state.file} />
                                }
                            </DialogContent>
                        </Dialog> */}

                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(Helps);