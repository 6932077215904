// import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import moment from "moment";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CardActionArea, Dialog, Typography, Button, CircularProgress, Box, DialogContent } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FlagIcon from '@material-ui/icons/Flag';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DownlodIcon from '@material-ui/icons/CloudDownload';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import TimesheetReportTab from '../Reports/TimesheetReport/TimesheetReportTab';
import { getDarkMode, timeToDecimal } from '../Helper/CommonThings';
import ViewListIcon from '@material-ui/icons/ViewList';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Collapse from '@material-ui/core/Collapse';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import DescriptionIcon from '@material-ui/icons/Description';
import TeamTaskStatusView from './TeamTaskStatusView';
import { CoffeeLoading } from 'react-loadingg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class TeamUserTasks extends Component {

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }


    constructor(props) {
        super()

        this.state = {
            responseData: null,
            data: [],
            projectLookUp: {},
            emLookUp: {},
            open: false,
            dialogData: null,
            isLoading: true,
            dataAll: [],
            miscellanious: [],
            ready_to_start: [],
            soon_due: [],
            start_soon: [],
            later_due: [],
            overdue: [],
            completed: [],
            selectedType: '',
            taskStatus: {
                ready_to_start: 'Ready To Start',
                miscellanious: 'No Dates Info',
                start_soon: 'Start Soon',
                soon_due: 'Soon Due',
                overdue: 'Overdue',
                completed: 'Completed'
            },
            allUsers: [],
            hoursLoading: false,
            listOpen: true,
            coffeeLoading: false
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })

        // var gids_ids = "0";
        // for (let i = 0; i < this.props.value.length; i++) {
        //     const element = this.props.value[i];
        //     gids_ids = gids_ids + "," + element.gid
        // }
        // Service.get("/bb/api/team/access/?gid=" + gids_ids, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         var uids_ids = "0"
        //         for (let i = 0; i < res.data.length; i++) {
        //             const element = res.data[i];
        //             if (element.uid !== user.id) {
        //                 uids_ids = uids_ids + "," + element.uid;
        //             }

        //         }
        //         this.fetchOps(uids_ids);
        //     })
        //     .catch(error => {
        //         errorMessage(this.props, error)
        //     });
        this.getAlldata()
    }

    getAlldata() {
        Service.get(`/bb/api/user/report/view/team_workload/?uid=` + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                let { ds, project, task, user_task, wbs, users } = res.data;

                this.users = users;

                for (let i = 0; i < task.length; i++) {
                    let task_users = []
                    for (let j = 0; j < user_task.length; j++) {
                        if (task[i].id === user_task[j].task_id) {
                            for (let k = 0; k < users.length; k++) {
                                if (user_task[j].user_id === users[k].id) {
                                    // task[i].user = users[k]
                                    task_users.push(users[k])
                                }
                            }
                        }
                    }
                    task[i].users = task_users
                    for (let j = 0; j < wbs.length; j++) {
                        if (task[i].t_ohlwbs === wbs[j].id) {
                            task[i].wbs = wbs[j];

                            for (let k = 0; k < ds.length; k++) {
                                if (wbs[j].pid === ds[k].id) {
                                    task[i].ds = ds[k];
                                    for (let l = 0; l < project.length; l++) {
                                        if (ds[k].subproject_pid === project[l].id) {
                                            task[i].project = project[l]
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // console.log(task)


                // let data = []

                for (let i = 0; i < task.length; i++) {
                    const ele = task[i];

                    if (!ele.act_exp_start) {
                        ele.act_exp_start = ele.from_date
                    }
                    if (!ele.act_exp_finish) {
                        ele.act_exp_finish = ele.to_date
                    }
                }
                this.setState({
                    responseData: res.data
                })
                // console.log(data, 'data')
                this.dataAll = task
                this.statusReport(task)
            })
            .catch(e => {
                console.log(e)
            })
    }

    // fetchOps(ids) {
    //     Service.get(`/bb/api/tender/user/task/?uid=` + ids, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             var viewPid = []
    //             var pid = "0"
    //             var data1 = []
    //             let allUsers = {}
    //             for (let i1 = 0; i1 < res.data.length; i1++) {
    //                 const element = res.data[i1];
    //                 allUsers = { ...allUsers, ...{ [element.user_id]: element.user_data } }
    //                 if (!element.tasks_roles_data.t_tasks_data.act_exp_start) {
    //                     element.tasks_roles_data.t_tasks_data.act_exp_start = element.tasks_roles_data.t_tasks_data.from_date
    //                 }
    //                 if (!element.tasks_roles_data.t_tasks_data.act_exp_finish) {
    //                     element.tasks_roles_data.t_tasks_data.act_exp_finish = element.tasks_roles_data.t_tasks_data.to_date
    //                 }
    //                 pid = pid + "," + element.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid
    //                 viewPid.push({ 'com_ttid': element.task_id, 'com_uid': element.user_id })
    //                 element.user_name = element.user_data.last_name + ", " + element.user_data.first_name

    //                 var goon = true
    //                 for (let i = 0; i < data1.length; i++) {
    //                     const element1 = data1[i];
    //                     if (element.task_id === element1.task_id) {
    //                         goon = false
    //                     }
    //                 }

    //                 if (goon) {
    //                     data1.push(element)
    //                 }

    //             }
    //             // alert("qwerty")
    //             this.setState({ data: data1, dataAll: res.data, allUsers: allUsers }, () => {
    //                 this.statusReport(this.state.data)
    //                 if (viewPid.length !== 0) {
    //                     Service.patch("/bb/api/team/tasks/get_acthours/", {
    //                         headers: {
    //                             Authorization: "Token " + token,
    //                         },
    //                         data: viewPid,
    //                     })
    //                         .then(res12 => {

    //                             // for (let j = 0; j < res12.data.length; j++) {
    //                             //     const element13 = res12.data[j];
    //                             //     for (let i = 0; i < res.data.length; i++) {
    //                             //         const element12 = res.data[i];
    //                             //         if (element12.task_id === element13.id && element12.user_id === element13.id2) {
    //                             //             element12.actual_hours = timeToDecimal(element13.hours_actual)
    //                             //             element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
    //                             //             element12.total_alloc_hours = element13.allocated
    //                             //             element12.actual_and_inprogress = (element12.actual_hours + element12.inprogress_hours) + "/" + element12.total_alloc_hours
    //                             //             element12.tender_name_str = this.state.projectLookUp[element12.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]

    //                             //         }
    //                             //         // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                             //     }

    //                             // }
    //                             // console.log(dataD)
    //                             let data = [...data1]
    //                             let dataAll = res.data
    //                             var viewPid = []
    //                             let updatedArr = {}
    //                             for (let i = 0; i < data.length; i++) {
    //                                 const element = data[i];
    //                                 let matchedTask = []
    //                                 for (let j = 0; j < dataAll.length; j++) {
    //                                     const element2 = dataAll[j];
    //                                     if (element.task_id === element2.task_id) {
    //                                         matchedTask.push(element2)
    //                                         viewPid.push({ 'com_ttid': element2.task_id, 'com_uid': element2.user_id })
    //                                     }
    //                                 }
    //                                 updatedArr = { ...updatedArr, ...{ [element.task_id]: [...matchedTask] } }
    //                             }

    //                             console.log(updatedArr, 'Updated')
    //                             console.log(viewPid)


    //                             for (let i = 0; i < data.length; i++) {
    //                                 let ele = data[i];
    //                                 // let users = [];
    //                                 let uniqueUsers = []
    //                                 for (let j = 0; j < res12.data.length; j++) {
    //                                     let ele2 = res12.data[j]
    //                                     if (ele.task_id === ele2.id) {
    //                                         let allocatedUsers = updatedArr[ele.task_id];
    //                                         for (let k = 0; k < allocatedUsers.length; k++) {
    //                                             if (allocatedUsers[k].user_id === ele2.id2) {
    //                                                 let user = allUsers[ele2.id2]
    //                                                 if (uniqueUsers.indexOf(user.id) === -1) uniqueUsers.push(user.id)
    //                                                 // users.push(user)
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                                 ele.uniqueUsers = uniqueUsers
    //                                 // ele.users = users
    //                             }

    //                             let newArr = []
    //                             for (let i = 0; i < data.length; i++) {
    //                                 let ele = data[i]
    //                                 let users = []
    //                                 for (let j = 0; j < ele.uniqueUsers.length; j++) {
    //                                     let user = this.state.allUsers[ele.uniqueUsers[j]]
    //                                     for (let k = 0; k < res12.data.length; k++) {
    //                                         let ele2 = res12.data[k]
    //                                         if (ele2.id === ele.task_id && ele2.id2 === user.id) {
    //                                             user.actual_hours = timeToDecimal(ele2.hours_actual)
    //                                             user.inprogress_hours = timeToDecimal(ele2.hours_ongoing)
    //                                             user.total_alloc_hours = ele2.allocated
    //                                             user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
    //                                             user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
    //                                             // user.tender_name_str = this.props.projectLookUp[ele.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
    //                                         }
    //                                     }
    //                                     users.push(user)
    //                                 }
    //                                 ele.users = users
    //                                 newArr.push(JSON.parse(JSON.stringify(ele)))
    //                             }
    //                             console.log(newArr, 'new')

    //                             // this.setState({
    //                             //     data: res.data,
    //                             // })
    //                         })
    //                         .catch(error => {
    //                             console.log(error)
    //                             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
    //                                 variant: 'error'
    //                             });

    //                         });
    //                     Service.get(`/bb/api/tender/project/?id=` + pid, {
    //                         headers: {
    //                             Authorization: "Token " + token,
    //                         },
    //                     })
    //                         .then(res31 => {

    //                             var lookupcreation = {}
    //                             let emCreation = {}
    //                             for (let i = 0; i < res31.data.length; i++) {
    //                                 const element = res31.data[i];
    //                                 if (element.tenderbid_data) emCreation[element.id] = element.tenderbid_data.pdm_data;
    //                                 if (element.tender_name) {
    //                                     lookupcreation[element.id] = element.tender_name
    //                                 } else {
    //                                     lookupcreation[element.id] = element.name
    //                                 }
    //                                 for (let i = 0; i < res.data.length; i++) {
    //                                     const element12 = res.data[i];
    //                                     if (element12.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid === element.id) {
    //                                         if (element.tender_name) {
    //                                             element12.tender_name_str = element.tender_name
    //                                         } else {
    //                                             element12.tender_name_str = element.name
    //                                         }

    //                                         break;
    //                                     }
    //                                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                                 }
    //                             }

    //                             this.setState({
    //                                 projectLookUp: lookupcreation,
    //                                 emLookUp: emCreation,
    //                                 isLoading: false,
    //                             })


    //                         })
    //                         .catch(error => {
    //                             console.log(error)
    //                             errorMessage(this.props, error)
    //                         });
    //                 }

    //             })

    //         })
    //         .catch(error => {
    //             console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    statusReport(data) {

        let teamLeadOrEM = this.props.value.length > 0;
        console.log(this.props.value, teamLeadOrEM)

        let ready_to_start = [];
        let miscellanious = [];
        let start_soon = [];
        let soon_due = [];
        let later_due = [];
        let overdue = []
        let completed = [];

        // let unique = []
        for (let i = 0; i < data.length; i++) {
            let task = data[i]
            if (task.project.tenderbid_data && task.project.tenderbid_data.pdm_data) {
                if (this.props.uid === task.project.tenderbid_data.pdm_data.id) {
                    teamLeadOrEM = true
                }
            }
            if ((!task.act_exp_start || !task.act_exp_finish)
                && task.comp_percnt !== 100) {
                task.status = 'No Dates Info';
                miscellanious.push(task)
            } else if (moment(task.act_exp_start).isBefore(moment())
                && moment(task.act_exp_finish).isAfter(moment())
                && task.comp_percnt === 0) {
                task.status = 'Ready To Start';
                ready_to_start.push(task)
            } else if (moment(task.act_exp_finish).isAfter(moment().subtract(91, 'days')) && moment(task.act_exp_finish).isBefore(moment())
                && 0 < task.comp_percnt && task.comp_percnt < 100) {
                task.status = 'Soon Due';
                soon_due.push(task)
            } else if (moment(task.act_exp_finish).isBefore(moment())
                && task.comp_percnt >= 0
                && task.comp_percnt < 100) {
                task.status = 'Overdue';
                overdue.push(task)
            } else if (moment(task.act_exp_start).isAfter(moment())
                && moment(task.act_exp_finish).isAfter(moment())
                && task.comp_percnt === 0) {
                task.status = 'Start Soon';
                start_soon.push(task)
            } else if (task.comp_percnt >= 100) {
                task.status = 'Completed';
                completed.push(task)
            } else if (moment(task.act_exp_finish).isAfter(moment())) {
                task.status = 'Later Due';
                later_due.push(task)
            }
        }

        // for (let i = 0; i < data.length; i++) {
        //     let task = data[i]
        //     if (task.project.tenderbid_data && task.project.tenderbid_data.pdm_data) {
        //         if (user.id === task.project.tenderbid_data.pdm_data.id) {
        //             teamLeadOrEM = true
        //         }
        //     }
        //     if ((!task.act_exp_start || !task.act_exp_finish)
        //         && task.comp_percnt !== (teamLeadOrEM ? 100 : 60)) {
        //         miscellanious.push(task)
        //     }
        //     if (moment(task.act_exp_start).isBefore(moment())
        //         && moment(task.act_exp_finish).isAfter(moment())
        //         && task.comp_percnt === 0) {
        //         ready_to_start.push(task)
        //     }
        //     if (moment(task.act_exp_start).isBefore(moment())
        //         && moment(task.act_exp_finish).isAfter(moment())
        //         && task.comp_percnt > 0
        //         && task.comp_percnt < (teamLeadOrEM ? 100 : 60)) {
        //         soon_due.push(task)
        //     }
        //     if (moment(task.act_exp_start).isBefore(moment())
        //         && moment(task.act_exp_finish).isBefore(moment())
        //         && task.comp_percnt > 0
        //         && task.comp_percnt < (teamLeadOrEM ? 100 : 60)) {
        //         overdue.push(task)
        //     }
        //     if (moment(task.act_exp_start).isAfter(moment())
        //         && moment(task.act_exp_finish).isAfter(moment())
        //         && task.comp_percnt === 0) {
        //         start_soon.push(task)
        //     }
        //     if (task.comp_percnt === (teamLeadOrEM ? 100 : 60)) {
        //         completed.push(task)
        //     }
        // }
        this.setState({ miscellanious, ready_to_start, soon_due, later_due, overdue, start_soon, completed, isLoading: false, data })
    }

    cardClick(status) {
        if (this.state.isLoading) return;
        this.setState({ open: true, selectedType: status })
    }

    getName(id) {
        return this.state.projectLookUp[id]
    }

    downloadExcel() {
        let data = this.state.data;
        if (this.state.hoursLoading === true) return;
        // let newArr = this.state.data;
        // let newArr = []
        console.log(data)
        let viewPid = []
        try {
            for (let i = 0; i < data.length; i++) {
                let users = data[i].users
                // console.log(users, i)
                for (let j = 0; j < users.length; j++) {
                    viewPid.push({ 'com_ttid': data[i].id, 'com_uid': users[j].id })
                }
            }
            console.log(viewPid)
        }
        catch (e) { console.log(e) }
        if (viewPid.length !== 0) {
            this.setState({ hoursLoading: true, coffeeLoading: true })
            Service.patch("/bb/api/team/tasks/get_acthours/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: viewPid,
            })
                .then(res12 => {
                    let newArr = []
                    for (let i = 0; i < data.length; i++) {
                        let ele = data[i]
                        ele.total_hours = 0
                        ele.actual_and_inprogress = 0
                        if(ele.comp_percnt === 100) continue;
                        for (let j = 0; j < ele.users.length; j++) {
                            let user = ele.users[j]
                            for (let k = 0; k < res12.data.length; k++) {
                                let ele2 = res12.data[k]
                                if (ele2.id === ele.id && ele2.id2 === user.id) {
                                    user.actual_hours = timeToDecimal(ele2.hours_actual)
                                    user.inprogress_hours = timeToDecimal(ele2.hours_ongoing)
                                    user.total_alloc_hours = ele2.allocated
                                    user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours)
                                    user.total_hours = user.total_alloc_hours
                                    ele.actual_and_inprogress += user.actual_and_inprogress
                                    ele.total_hours += user.total_hours
                                    // user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
                                    // user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
                                    // user.tender_name_str = this.props.projectLookUp[ele.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                                }
                            }
                        }
                        newArr.push(JSON.parse(JSON.stringify(ele)))
                    }
                    // console.log(newArr, 'new')
                    var sheets = ["Team User Task"]
                    var sheetData = {}
                    var exportData = {}
                    sheetData["Team User Task"] = {
                        '!ref': "A1:J" + (newArr.length + 1),
                        '!cols': [
                            { wch: 35 }
                        ],
                        'A1': { t: "s", v: "Project" },
                        'B1': { t: "s", v: "Deliverable Schedule" },
                        'C1': { t: "s", v: "WBS" },
                        'D1': { t: "s", v: "Task Id" },
                        'E1': { t: "s", v: "Tasks" },
                        'F1': { t: "s", v: "Required Start Date" },
                        'G1': { t: "s", v: "Required End Date" },
                        'H1': { t: "s", v: "Status" },
                        'I1': { t: "s", v: "% Complete" },
                        'J1': { t: "s", v: "Progress Hours/Total Hours" },
                    }
                    let sheet = sheetData['Team User Task']
                    for (let i = 0; i < newArr.length; i++) {
                        const element = newArr[i];
                        if (element.comp_percnt !== 100) {
                            sheet["A" + (i + 2)] = { t: "s", v: element.project.tender_name }
                            sheet["B" + (i + 2)] = { t: "s", v: element.ds.name }
                            sheet["C" + (i + 2)] = { t: "s", v: element.wbs.name }
                            sheet["D" + (i + 2)] = { t: "s", v: element.id }
                            sheet["E" + (i + 2)] = { t: "s", v: element.name }
                            sheet["F" + (i + 2)] = { t: "s", v: element.act_exp_start ? moment(element.act_exp_start).format('DD/MM/YYYY') : '' }
                            sheet["G" + (i + 2)] = { t: "s", v: element.act_exp_finish ? moment(element.act_exp_finish).format('DD/MM/YYYY') : '' }
                            sheet["H" + (i + 2)] = { t: "s", v: element.status }
                            sheet["I" + (i + 2)] = { t: "s", v: element.comp_percnt }
                            sheet["J" + (i + 2)] = { t: "s", v: element.total_hours ? element.actual_and_inprogress +"/"+ element.total_hours : '' }
                        }
                    }
                    exportData = {
                        'SheetNames': sheets,
                        'Sheets': sheetData
                    }
                    // console.log(exportData)
                    var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
                    saveAs(blob, "Team User Task " + moment().format() + ".xlsx");
                    this.setState({ hoursLoading: false, coffeeLoading: false })
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ coffeeLoading: false })
                    this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                        variant: 'error'
                    });
                });
        }
    }


    getUsers(users) {
        // console.log(users);
        if (!users) { return '' }
        let str = '';
        users.forEach((ele, i) => {
            str += ele.first_name + ' ' + ele.last_name + ', '  //+ ' (' + ele.actual_and_inprogress + '), '
            // return (<><p style={{ margin: '5px 0' }}>{ele.last_name + ', ' + ele.first_name + ' (' + ele.actual_and_inprogress + ')'}</p></>)
        })
        return str;
    }

    render() {
        const handleClose = () => {
            this.setState({ open: false, })
        };

        const darkMode = getDarkMode() === 'dark'
        // const handleListClick = () => {
        //     this.setState({ listOpen: !this.state.listOpen, })
        // }
        return (
            <div>
                {
                    // this.state.isLoading ? <CircleLoading color={getPrimaryColor()} style={{ position: 'relative', margin: 'auto' }} /> :
                    <div>
                        {!this.state.isLoading ?
                            <Box style={{ textAlign: 'right' }}>

                                {/* <Button style={{ marginTop: '10px', marginRight: '20px' }}
                                    endIcon={<ViewListIcon />}
                                    onClick={() => this.cardClick('all')}
                                >
                                    View All Task
                                </Button> */}
                                <Button style={{ marginTop: '10px', marginRight: '10px' }}
                                    endIcon={<DownlodIcon />}
                                    onClick={() => this.downloadExcel()}
                                >
                                    {/* {this.state.hoursLoading ? 'Please Wait...' : 'Download Report'} */}
                                    Download All Task
                                </Button>
                            </Box>
                            : null}
                        <div style={{ display: 'flex' }}>
                            {/* <Grid container spacing={2} style={{ margin: '15px 0', width: '100%', marginTop: '5px' }}>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#2196F3' : '#64B5F6', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('all')}>
                                    <CardHeader
                                        avatar={
                                            <ViewListIcon />
                                        }
                                        // title={this.state.miscellanious.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.data.length}
                                        subheader="All Tasks"
                                    />
                                </CardActionArea>
                            </Card>

                            <Card style={{ background: darkMode ? '#ccb800' : '#FFF176', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('start_soon')} >
                                    <CardHeader
                                        avatar={
                                            <AccessTimeIcon />
                                        }
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.start_soon.length}
                                        subheader="Start Soon"
                                    />
                                </CardActionArea>
                                {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}

                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#9fad1f' : '#DCE775', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('ready_to_start')}>
                                    <CardHeader
                                        avatar={
                                            <FlagIcon />
                                        }
                                        // title={this.state.ready_to_start.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.ready_to_start.length}
                                        subheader="Ready To Start "
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#da8bc5' : '#dc92d9', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('later_due')}>
                                    <CardHeader
                                        avatar={
                                            <ErrorOutlineIcon />
                                        }
                                        // title={this.state.soon_due.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.later_due.length}
                                        subheader="Later Due"
                                    />
                                </CardActionArea>
                            </Card>
                            <Card style={{ background: darkMode ? '#e68a00' : '#FFB74D', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('soon_due')}>
                                    <CardHeader
                                        avatar={
                                            <ErrorOutlineIcon />
                                        }
                                        // title={this.state.soon_due.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.soon_due.length}
                                        subheader="Soon Due"
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#c71f1f' : '#EF9A9A', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('overdue')}>
                                    <CardHeader
                                        avatar={
                                            // <Avatar sx={{ bgcolor: '#F44336' }} aria-label="recipe">
                                            //     O
                                            // </Avatar>
                                            <NotificationsIcon />
                                        }
                                        // title={this.state.overdue.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.overdue.length}
                                        subheader="Overdue"
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#5c862d' : '#AED581', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('completed')}>
                                    <CardHeader
                                        avatar={
                                            <CheckCircleOutlineIcon />
                                        }
                                        // title={this.state.completed.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.completed.length}
                                        subheader="Completed"
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#347f77' : '#4DB6AC', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('miscellanious')}>
                                    <CardHeader
                                        avatar={
                                            <HourglassEmptyIcon />
                                        }
                                        // title={this.state.miscellanious.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.miscellanious.length}
                                        subheader="No Dates Info"
                                    />
                                </CardActionArea>
                            </Card>

                        </div>
                        <Dialog maxWidth="sm" open={this.state.coffeeLoading} aria-labelledby="form-dialog-title">
                            <DialogContent>
                                <Box m={5} p={2}>
                                    <CoffeeLoading />
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </div>
                }

                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography >View All Tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.state.isLoading ? null :
                            <TeamUserTaskTable
                                data={[...this.state.soon_due, ...this.state.start_soon, ...this.state.ready_to_start, ...this.state.overdue, ...this.state.miscellanious]}
                                dataAll={this.dataAll}
                                status={this.state.taskStatus[this.state.selectedType]}
                                projectLookUp={this.state.projectLookUp}
                                allUsers={this.users}
                                viewAll={true}
                            />}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography >Timesheet Reports</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        < TimesheetReportTab uid={user.id} />
                    </AccordionDetails>
                </Accordion> */}

                {/* <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >

                    <ListItem button onClick={handleListClick}>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="Timesheet Reports (Click here to view)" />
                        {this.state.listOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.listOpen} timeout="auto" unmountOnExit> */}
                <TimesheetReportTab uid={user.id} companyFilter={0} />
                {/* </Collapse>
                </List> */}

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar style={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Team Members Workload
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <TeamUserTaskTable
                        data={this.state.selectedType === 'all' ?
                            [...this.state.soon_due, ...this.state.start_soon, ...this.state.ready_to_start, ...this.state.overdue, ...this.state.miscellanious]
                            : this.state[this.state.selectedType]}
                        dataAll={this.dataAll}
                        status={this.state.taskStatus[this.state.selectedType]}
                        projectLookUp={this.state.projectLookUp}
                        allUsers={this.users}
                        viewAll={this.state.selectedType === 'all' ? true : false}
                    /> */}
                    <TeamTaskStatusView
                        data={this.state.selectedType === 'all' ?
                            this.state.data
                            : this.state[this.state.selectedType]}
                        responseData={this.state.responseData}
                        dataAll={this.dataAll}
                        status={this.state.taskStatus[this.state.selectedType]}
                        projectLookUp={this.state.projectLookUp}
                        allUsers={this.users}
                        viewAll={this.state.selectedType === 'all' ? true : false}
                        teamOrProj={false}
                    />
                </Dialog>

                {/* <MaterialTable
                    title="Team Members Workload"
                    columns={[
                        {
                            title: 'Project', field: 'tender_name_str',
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.state.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        { title: 'Deliverables Schedule', field: 'tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.name', },
                        { title: 'WBS', field: 'tasks_roles_data.t_tasks_data.t_wbs_data.name', },
                        {
                            title: 'Tasks', field: 'tasks_roles_data.t_tasks_data.name',
                            render: rowData => rowData.tasks_roles_data.t_tasks_data.decs ? rowData.tasks_roles_data.t_tasks_data.decs : rowData.tasks_roles_data.t_tasks_data.name,
                        },
                        {
                            title: 'Required Start Date',
                            field: 'tasks_roles_data.t_tasks_data.act_exp_start',
                            type: 'date',
                        },
                        {
                            title: 'Required End Date',
                            field: 'tasks_roles_data.t_tasks_data.act_exp_finish',
                            type: 'date',
                        },
                        {
                            title: 'Complete (%)', field: 'tasks_roles_data.t_tasks_data.comp_percnt',
                            lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                        },
                        // {
                        //     title: 'Actual and Inprogress Hours/Total Allocated', field: 'actual_and_inprogress', editable: 'never',
                        //     render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                        // },

                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    style={{ marginTop: '25px' }}
                    options={{
                        padding: "dense",
                        filtering: true,
                        // exportButton: true,
                        doubleHorizontalScroll: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        // exportButton: { csv: true },
                        // exportCsv: (columns, data) => {
                        //     formatCSVData(columns, data)
                        // },
                    }}
                    detailPanel={rowData => {
                        // alert("")
                        return (
                            <TeamUsersTasksDetail value={JSON.parse(JSON.stringify(this.state.dataAll))} task={rowData} />
                        )
                    }}

                /> */}

                {/* <Dialog onClose={handleClose} fullWidth="true" maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {this.state.dialogData ? (this.state.projectLookUp[this.state.dialogData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid] + " - " + this.state.dialogData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.name) : ("")}
                    </DialogTitle>
                    <DialogContent dividers>
                        <TeamUsersTasksDetail value={this.state.dataAll} task={this.state.dialogData} />
                    </DialogContent>
                </Dialog> */}

            </div >
        )
    }
}

// function addZeroes(num) {
//     const dec = num.toString().split('.')[1]
//     const len = dec ? 1 : null
//     if (len) {
//         return parseFloat(Number(num).toFixed(len))
//     } else {
//         return parseFloat(Number(num))
//     }
// }

// function timeToDecimal(t) {
//     var arr = t.split(':');
//     var dec = parseInt((arr[1] / 6) * 10, 10);

//     return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
// }

export default withSnackbar(TeamUserTasks)